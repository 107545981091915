import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "faq"
    }}>{`FAQ`}</h1>
    <h2 {...{
      "id": "storygram-is-not-showing-up"
    }}>{`Storygram is not showing up`}</h2>
    <ul>
      <li parentName="ul">{`Check the console; if there is a warning "Storygram: No data after filtering" then you have filtered out all events and/or actors`}</li>
      <li parentName="ul">{`If you have set the root parameter in the config object, make sure that it is a valid css selector and that the DOM element it points on exists`}</li>
      <li parentName="ul">{`Set verbose: true in the config object. You'll get additional information about data import problems, missing keys, corrupted events or actors and filtering issues`}</li>
    </ul>
    <h2 {...{
      "id": "storygram-is-appended-at-the-end-of-my-page"
    }}>{`Storygram is appended at the end of my page`}</h2>
    <ul>
      <li parentName="ul">{`By default, the Storygram is appended at the body tag of your html document. Change the root parameter in your config object to select the correct DOM element to which it should be appended`}</li>
    </ul>
    <h2 {...{
      "id": "storygram-jumps-in-size"
    }}>{`Storygram jumps in size`}</h2>
    <ul>
      <li parentName="ul">{`The Storygram will always show all events and actors that pass filtering, which can result in big changes in size. It is important to get used to the filtering methods to be able to control these changes`}</li>
    </ul>
    <h2 {...{
      "id": "events-are-too-near"
    }}>{`Events are too near`}</h2>
    <ul>
      <li parentName="ul">{`Adapt the eventPadding parameter of the config object`}</li>
      <li parentName="ul">{`Set the parameter eventValueScaling to a very low value, for example  0.001`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      